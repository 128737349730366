<template>
  <v-dialog
    v-model="showFormDialog"
    @click:outside="closeForm"
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>
      <v-form ref="form" v-model="valid" @submit.prevent="saveItem">
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" md="12">
                <v-text-field
                  v-model="form.name"
                  prepend-icon="mdi-format-title"
                  label="Permission Name"
                  :rules="rules.name"
                ></v-text-field>

                <v-text-field
                  v-model="form.guard_name"
                  label="Guard Name"
                  prepend-icon="mdi-shield-half-full"
                  :rules="rules.guard_name"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="gray"
            :disabled="sending || savedItem != null"
            @click="closeForm"
            >{{ $t("Cancel") }}</v-btn
          >
          <v-btn
            type="submit"
            :disabled="sending || savedItem != null"
            color="primary"
            >{{ $t("Save") }}</v-btn
          >
        </v-card-actions>
      </v-form>
      <v-progress-linear
        v-if="sending"
        color="deep-purple accent-4"
        indeterminate
        rounded
        height="6"
      ></v-progress-linear>
      <v-alert v-if="errorMessage" tile class="mb-0" type="error">{{
        errorMessage
      }}</v-alert>
      <v-alert v-if="savedItem" tile type="success" class="mb-0">
        The permission
        <strong>{{ savedItem.name }}</strong>
        was {{ editedItem === null ? "created" : "edited" }} with success!
      </v-alert>
    </v-card>
  </v-dialog>
</template>

<script>
import crudMixin from "mixins/crudForm";

export default {
  mixins: [crudMixin],
  props: {
    data: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      apiQuery: "/permissions/",
      rules: {
        name: [(v) => !!v || "Required."],
        guard_name: [
          (v) => (this.editedItem === null ? !!v || "Required." : true),
          // () => this.serverErrors,
        ],
      },
      defaultItem: {
        guard_name: "api",
      },
    };
  },

  computed: {
    formTitle() {
      return this.editedItem === null
        ? this.$t("roles.add permission")
        : this.$t("roles.edit permission");
    },
  },

  methods: {},
};
</script>
